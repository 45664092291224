import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import DetailsPageHeader from "../../components/shared/DetailsPageHeader";
import Content from "../../layout/content/Content";
import Head from "../../layout/head/Head";

import { useBeManualFetcher } from "../../hooks/useBeManualFetcher";
import { Card } from "reactstrap";
import { Block, BlockBetween, BlockTitle } from "../../components/Component";
import UpdatableText from "../../components/shared/UpdatabaleText";
import { opendaysService } from "../../services/openDaysService";
import { useOpenDaysContext } from "../../hooks/useOpendaysContext";
import { formatDate } from "../../utilities/dateTimeUtils";

const OpendaysParticipantDetailsPage = () => {
  const { id } = useParams();
  const { applicant, setApplicant } = useOpenDaysContext();
  const [onFetch, isLoading] = useBeManualFetcher();

  async function handleStudentDataFetch() {
    await onFetch({
      action: () => opendaysService.getOpendaysParticipantById(id),
      onLoad: (response) => setApplicant(response),
    });
  }

  useEffect(() => {
    handleStudentDataFetch();
  }, [id]);

  return (
    <>
      <Head title="Employee Details" />
      <Content>
        <DetailsPageHeader
          title={"Participant -"}
          name={`${applicant?.firstName ? applicant?.firstName : ""} ${
            applicant?.lastName ? applicant?.lastName : ""
          } ${applicant?.middleName ? applicant?.middleName : ""}`}
          createdDate={applicant?.createdAt}
          goBackPath={"/opendays-participants"}
          isLoading={isLoading.id}
        />
        <Card className="card-inner">
          <Block>
            <form>
              <BlockBetween className="mb-3">
                <BlockTitle tag="h5">Personal Information</BlockTitle>
              </BlockBetween>
              <div className="profile-ud-list">
                <div className="profile-ud-item position-relative">
                  <UpdatableText
                    title="Attendees"
                    defaultValue={applicant?.attendees}
                    name={"attendees"}
                    isWider
                  />
                  <UpdatableText
                    title="Date"
                    defaultValue={formatDate(applicant?.date)}
                    name={"date"}
                    isWider
                  />
                  <UpdatableText
                    title="School"
                    defaultValue={applicant?.school}
                    name={"school"}
                    isWider
                  />
                  <UpdatableText
                    title="Hear"
                    defaultValue={applicant?.hear}
                    name={"hear"}
                    isWider
                  />
                </div>
                <div className="profile-ud-item">
                  <UpdatableText
                    title="Interest"
                    defaultValue={applicant?.interest}
                    name={"interest"}
                    isWider
                  />
                  <UpdatableText
                    title="Status"
                    defaultValue={applicant?.status}
                    name={"status"}
                    isWider
                  />
                  <UpdatableText
                    title="Message"
                    defaultValue={applicant?.message}
                    name={"message"}
                    isWider
                  />
                </div>
              </div>
            </form>
          </Block>
        </Card>
      </Content>
    </>
  );
};

export default OpendaysParticipantDetailsPage;
