import React, { useCallback, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import * as XLSX from "xlsx";

import MainPageHOC from "../../layout/hoc/MainPageHOC";
import MainPageTableHeader from "../../layout/body/MainPageTableHeader";

import PaginationComponent from "../../components/pagination/PaginationComponent";
import FetcherComponent from "../../components/shared/FetcherComponent";
import MainPageHeader from "../../components/shared/MainPageHeader";

import { useOlympiadPageContext } from "../../hooks/useOlympiadPageContext";
import OlympiadPageTableBody from "../../components/olympiadPage/OlympiadPageTableBody";
import { olympiadService } from "../../services/olympiadService";

const OlympiadPage = () => {
  const {
    applicants,
    setApplicants,
    allApplicants,
    setAllApplicants,
    currentPage,
    setCurrentPage,
    totalPages,
    setTotalPages,
    totalRecords,
    setTotalRecords,
    setPageSize,
    pageSize,
  } = useOlympiadPageContext();

  const [searchParams] = useSearchParams();
  const currentPageQueryString = searchParams.get("current-page");
  const pageSizeQueryString = searchParams.get("page-size");

  const fetchUsers = useCallback(
    async () =>
      await olympiadService.getOlympiadParticipants({
        currentPage: currentPageQueryString,
        pageSize: pageSizeQueryString,
      }),
    [currentPageQueryString, pageSizeQueryString]
  );

  const fetchAllApplicants = useCallback(async () => {
    try {
      const response = await olympiadService.getOlympiadParticipants({
        currentPage: 1,
        pageSize: totalRecords,
      });
      setAllApplicants(response?.content || []);
    } catch (error) {
      console.error("Error fetching all applicants:", error);
    }
  }, [totalRecords, setAllApplicants]);

  const handleOnLoadFetchedUsers = useCallback(
    (res) => {
      setApplicants(res?.content);
      setCurrentPage(res?.number + 1);
      setTotalPages(res?.totalPages);
      setTotalRecords(res?.totalElements);
      setPageSize(res?.size);
      const calculatedTotalPages = Math.ceil(res?.totalElements / res?.size);
      setTotalPages(calculatedTotalPages);
    },
    [setApplicants, setCurrentPage, setTotalPages, setTotalRecords, setPageSize]
  );

  const participantDataChecker = useCallback(
    () => (applicants?.length > 0 ? true : false),
    [applicants]
  );

  const onExportClick = async () => {
    if (!allApplicants || allApplicants.length === 0) {
      alert("No applicants available for export.");
      return;
    }

    const newWorkBook = XLSX.utils.book_new();
    const newWorkSheet = XLSX.utils.json_to_sheet(allApplicants);

    XLSX.utils.book_append_sheet(newWorkBook, newWorkSheet, "NewSheet1");
    XLSX.writeFile(newWorkBook, "NewFile.xlsx");
  };

  useEffect(() => {
    if (applicants && applicants?.length > 0) {
      fetchAllApplicants();
    }
  }, [applicants]);

  return (
    <MainPageHOC
      headerTitle={"Olympiad Participants"}
      pageHeader={
        <MainPageHeader
          totalRecords={totalRecords}
          pageName="Olympiad Participants"
          redirectTo={"add"}
          disableExportButton={true}
          showExportButton={true}
          onExportClick={onExportClick}
        />
      }
      tableHeader={
        <MainPageTableHeader pageSize={pageSize} setPageSize={setPageSize} />
      }
      tableBody={
        <FetcherComponent action={fetchUsers} onLoad={handleOnLoadFetchedUsers}>
          <>
            <OlympiadPageTableBody
              users={applicants}
              setUsers={setApplicants}
            />
            <div className="card-inner">
              <PaginationComponent
                currentPageNumber={currentPage}
                totalPages={totalPages}
                totalRecords={totalRecords}
                status={pageSizeQueryString}
              />
            </div>
          </>
        </FetcherComponent>
      }
      hasDataForRendering={participantDataChecker}
    />
  );
};
export default OlympiadPage;
