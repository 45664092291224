import React, { Suspense, useLayoutEffect } from "react";
import { Routes, Route, Navigate } from "react-router-dom";

import Faq from "../pages/others/Faq";
import Terms from "../pages/others/Terms";
import StudentsPage from "../pages/users/StudentsPage";
import Error404Modern from "../pages/error/404-modern";
import EmployeesPage from "../pages/users/EmployeesPage";
import StudentDetailsPage from "../pages/users/StudentDetailsPage";
import EmployeeDetailsPage from "../pages/users/EmployeeDetailsPage";
import StudentPageContextProvider from "../providers/StudentPageContextProvider";
import EmployeePageContextProvider from "../providers/EmployeePageContextProvider";
import { useAuthContext } from "../hooks/useAuthContext";
import { USER_ROLES } from "../utilities/constants";
import NewsPageContextProvider from "../providers/NewsPageContextProvider";
import NewsPage from "../pages/news/NewsPage";
import NewsCreatePage from "../pages/news/NewsCreatePage";
import NewsCategoryPage from "../pages/newsCategory/NewsCategoryPage";
import NewsCategoryPageContextProvider from "../providers/NewsCategoryPageContextProvider";
import NewsCategoryCreatePage from "../pages/newsCategory/NewsCategoryCreatePage";
import NewsDetailsPage from "../pages/news/NewsDetailsPage";
import OlympiadPageContextProvider from "../providers/OlympiadPageContextProvider";
import OlympiadPage from "../pages/olympiad";
import OlimpiadParticipantDetailsPage from "../pages/olympiad/details";
import OpenDaysDetailsPage from "../pages/opendays/details";
import OpenDaysContextProvider from "../providers/OpendaysContextProvider";
import OpenDaysPage from "../pages/opendays";

const Pages = () => {
  const { user } = useAuthContext();
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  });

  return (
    <Suspense fallback={<div />}>
      <Routes>
        <Route path="*" element={<Error404Modern />} />
        {user?.roles?.length > 0 && (
          <Route
            path="/"
            element={
              user?.roles?.find((role) => role === USER_ROLES.ADMIN_STUDENT) ? (
                <Navigate to="/students" replace />
              ) : user?.roles?.find(
                  (role) => role === USER_ROLES.ADMIN_EMPLOYEE
                ) ? (
                <Navigate to="/employees" replace />
              ) : (
                <Navigate to="/news" replace />
              )
            }
          />
        )}
        <Route exact path={`/settings/terms-policy`} element={<Terms />} />
        <Route exact path={`/settings/faq`} element={<Faq />} />
        <Route
          exact
          path="/students"
          element={
            <StudentPageContextProvider>
              <StudentsPage />
            </StudentPageContextProvider>
          }
        />
        <Route
          path="/students/:id"
          element={
            <StudentPageContextProvider>
              <StudentDetailsPage />
            </StudentPageContextProvider>
          }
        />
        <Route
          exact
          path="/olympiad-participants"
          element={
            <OlympiadPageContextProvider>
              <OlympiadPage />
            </OlympiadPageContextProvider>
          }
        />

        <Route
          exact
          path="/olympiad-participants/:id"
          element={
            <OlympiadPageContextProvider>
              <OlimpiadParticipantDetailsPage />
            </OlympiadPageContextProvider>
          }
        />
        <Route
          exact
          path="/opendays-participants"
          element={
            <OpenDaysContextProvider>
              <OpenDaysPage />
            </OpenDaysContextProvider>
          }
        />

        <Route
          exact
          path="/opendays-participants/:id"
          element={
            <OpenDaysContextProvider>
              <OpenDaysDetailsPage />
            </OpenDaysContextProvider>
          }
        />
        <Route
          exact
          path="/employees"
          element={
            <EmployeePageContextProvider>
              <EmployeesPage />
            </EmployeePageContextProvider>
          }
        />
        <Route exact path="/employees/:id" element={<EmployeeDetailsPage />} />
        <Route
          exact
          path="/news"
          element={
            <NewsPageContextProvider>
              <NewsPage />
            </NewsPageContextProvider>
          }
        />
        <Route
          exact
          path="/news/:newsId"
          element={
            <NewsPageContextProvider>
              <NewsDetailsPage />
            </NewsPageContextProvider>
          }
        />
        <Route exact path="/news/create" element={<NewsCreatePage />} />
        <Route
          exact
          path="/news-category"
          element={
            <NewsCategoryPageContextProvider>
              <NewsCategoryPage />
            </NewsCategoryPageContextProvider>
          }
        />
        <Route
          exact
          path="/news-category/create"
          element={<NewsCategoryCreatePage />}
        />
      </Routes>
    </Suspense>
  );
};
export default Pages;
