import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";

import { DataTableItem, UserAvatar, Icon, DataTableRow } from "../Component";
import ConfirmationModal from "../modals/ConfirmationModal";

import { useBeManualFetcher } from "../../hooks/useBeManualFetcher";
import { DATE_FORMATS } from "../../utilities/constants";
import { formatDate } from "../../utilities/dateTimeUtils";
import EventEmitter, { EVENT_TOPICS } from "../../utilities/eventUtils";
import { opendaysService } from "../../services/openDaysService";

const OpendaysPageTableItems = ({ item, onSelectChange }) => {
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [onFetch] = useBeManualFetcher();

  const handleDeleteApplicantClick = () => setShowDeleteModal(true);

  const handleApplicantDelete = async (id) => {
    await onFetch({
      action: () => opendaysService.removeOpendaysParticipant(id),
      onLoad: () => {
        EventEmitter.emit(EVENT_TOPICS.ON_APPLICANT_DELETED, {
          deletedApplicantId: id,
        });

        setShowDeleteModal(false);
      },
      successMessage: "Participant successfully deleted",
    });
  };

  return (
    <DataTableItem>
      <DataTableRow className="nk-tb-col-check" noText>
        <div className="custom-control custom-control-sm custom-checkbox notext">
          <input
            type="checkbox"
            className="custom-control-input form-control"
            defaultChecked={item?.check}
            id={item.id + "pid-all"}
            key={Math.random()}
            onChange={(e) => onSelectChange(e, item.id)}
          />
          <label
            className="custom-control-label"
            htmlFor={item.id + "pid-all"}
          ></label>
        </div>
      </DataTableRow>
      <DataTableRow noText>
        <Link
          to={`${process.env.PUBLIC_URL}/opendays-participants/${item.id}`}
          state={item}
        >
          {formatDate(item?.date, DATE_FORMATS.DATE_REGISTERED)}
        </Link>
      </DataTableRow>
      <DataTableRow noText>
        <Link
          to={`${process.env.PUBLIC_URL}/opendays-participants/${item.id}`}
          state={item}
        >
          {item?.school}
        </Link>
      </DataTableRow>
      <DataTableRow noText>{item?.status}</DataTableRow>
      <DataTableRow noText>{item?.interest}</DataTableRow>
      <DataTableRow className="nk-tb-col-tools" noText>
        <ul className="nk-tb-actions gx-1">
          <li>
            <UncontrolledDropdown>
              <DropdownToggle
                tag="a"
                className="dropdown-toggle btn btn-icon btn-trigger"
              >
                <Icon name="more-h"></Icon>
              </DropdownToggle>
              <DropdownMenu right>
                <ul className="link-list-opt no-bdr">
                  <li>
                    <DropdownItem
                      tag="a"
                      href="#delete"
                      onClick={handleDeleteApplicantClick}
                    >
                      <Icon name="trash"></Icon>
                      <span>Delete</span>
                    </DropdownItem>
                  </li>
                </ul>
              </DropdownMenu>
            </UncontrolledDropdown>
          </li>
        </ul>
      </DataTableRow>
      <ConfirmationModal
        showModal={showDeleteModal}
        onClose={() => setShowDeleteModal(false)}
        onConfirm={() => handleApplicantDelete(item?.id)}
        confirmColor="danger"
        confirmTitle="Delete"
      />
    </DataTableItem>
  );
};

export default OpendaysPageTableItems;
