import isUndefined from "lodash/isUndefined";
import isNull from "lodash/isNull";
import {
  getOpendaysParticipantById,
  getOpendaysParticipants,
  removeOpendaysParticipant,
  updateOpendaysParticipantStatus,
} from "../repositories/opendaysRepository";

export const opendaysService = {
  getOpendaysParticipants: async ({ currentPage, pageSize }) => {
    const pageNumber =
      isUndefined(currentPage) || isNull(currentPage) ? 1 : currentPage;
    const itemsPerPage =
      isUndefined(pageSize) || isNull(pageSize) ? 20 : pageSize;

    return await getOpendaysParticipants({
      page: Number(pageNumber) - 1,
      size: itemsPerPage,
    });
  },

  getOpendaysParticipantById: async (id) =>
    await getOpendaysParticipantById(id),

  updateOpendaysParticipantStatus: async (status, body) =>
    await updateOpendaysParticipantStatus(status, body),

  removeOpendaysParticipant: async (id) => await removeOpendaysParticipant(id),
};
