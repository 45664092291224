import backendHttpRequest from "./backendHttpRequest";
import { config } from "../configs/repositoryConfig";
import { convertToQueryString } from "../utilities/httpUtils";
import { replaceAllStringByKeys } from "../utilities/stringUtils";

export const getOpendaysParticipants = async (filteredObject) => {
  const queryString = convertToQueryString(filteredObject);
  const resourceObject = {
    ...config.backendApiSettings.resources.getOpendaysParticipants,
  };
  const endpoint = resourceObject.endpoint;
  resourceObject.endpoint = `${endpoint}${
    queryString ? `?${queryString}` : ""
  }`;

  const apiUsers = await backendHttpRequest({ resourceObject });
  return apiUsers;
};

export const getOpendaysParticipantById = async (id) => {
  const resourceObject = {
    ...config.backendApiSettings.resources.getOpendaysParticipantById,
  };
  resourceObject.endpoint = replaceAllStringByKeys(
    resourceObject.endpoint,
    [":id"],
    [id]
  );

  const apiUser = await backendHttpRequest({ resourceObject });

  return apiUser;
};

export const updateOpendaysParticipantStatus = async (id, status) => {
  const resourceObject = {
    ...config.backendApiSettings.resources.updateOpendaysParticipantStatus,
  };
  const endpoint = resourceObject.endpoint;
  resourceObject.endpoint = `${endpoint}?${id}/${status}`;

  const apiUser = await backendHttpRequest({ resourceObject });

  return apiUser;
};

export const removeOpendaysParticipant = async (id) => {
  const resourceObject = {
    ...config.backendApiSettings.resources.deleteOpendaysParticipant,
  };
  const endpoint = resourceObject.endpoint;
  resourceObject.endpoint = endpoint.replace(":id", id);

  await backendHttpRequest({ resourceObject });

  return id;
};
